import React from "react";
import log from './../images/coffee-2.svg';


export const Header = () => {
  return (
    <header>
      <div className="relative bg-white">
        <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="/" className="flex items-center mb-[-10px]">
              <img src={log} alt="Barista Logo" className="h-11 mb-[10px] inline  ml-[8px] mr-[4px]" />
              <span className="font-sans text-3xl font-extrabold tracking-tight">
                Barista
                <span className="italic font-light text-gray-600">WP</span>
              </span>
            </a>
          </div>

          <nav as="nav" className="hidden md:flex space-x-10">
            <a
              href="/"
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Features
            </a>
            <a
              href="/join"
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Join Early Founders
            </a>

            <a
              href="https://raw.githubusercontent.com/fnpen/baristawp-plugin/main/CHANGELOG.md"
              target="_blank"
              rel="noreferrer"
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Changelog
            </a>
          </nav>

          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <a
              href="/suggest-feature"
              className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Suggest Feature
            </a>
            <a
              href="/contact"
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center bg-gradient-to-r from-sky-600 to-blue-600 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-sky-700 hover:to-blue-700"
            >
              Contact
            </a>
          </div>
        </div>
      </div>
    </header>
  )
}
