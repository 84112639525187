/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import Helmet from "react-helmet"
import { Footer } from "./Footer"
import { Header } from "./Header"

export const Layout = ({ nav, children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <div className="bg-white">
      <Header nav={nav}/>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-D14B632PPK"></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-D14B632PPK');`}
        </script>
      </Helmet>

      <main>{children}</main>

      <Footer />
    </div>
  )
}

